const productsArray = {
    main: [
        {
            title: 'Гравийный щебень',
            descriptor: 'фракции 3-8',
            price: 900
        },
        {
            title: 'Гравийный щебень',
            descriptor: 'фракции 5-10',
            price: 1250
        },
        {
            title: 'Гравийный щебень',
            descriptor: 'фракции 5-20',
            price: 1300
        },
        {
            title: 'Гравийный щебень',
            descriptor: 'фракции 20-40',
            price: 1200
        },
        {
            title: 'Гравийный щебень',
            descriptor: 'фракции 40-70',
            price: 1250
        },
        {
            title: 'Гравийный отсев',
            descriptor: 'фракции 0-5',
            price: 600
        },
        {
            title: 'ПГС',
            descriptor: '',
            price: 450
        },
        {
            title: 'ОПГС',
            descriptor: '',
            price: 500
        },
        {
            title: 'Гравийный щебень в мешках (Биг бегах)',
            descriptor: '',
            price: 600
        }
    
    ]
}
export default productsArray