import React, { useState } from "react";

import config from "../../../../config";

import './us.scss'
import './adaptive.scss'


function Us() {
    const [page, setPage] = useState(1);
    return (
        <React.Fragment>
                <div className="aboutUs">
                    <div className="container">
                        <div className="content">
                            <div className="text">
                                <div className="title">
                                    <span>О компании</span>
                                    <span><b>{config.company} —</b><br/>надежный партнер вашего бизнеса</span>
                                </div>
                                <div className="description">
                                Продажа и доставка сыпучих строительных материалов – приоритетная деятельность нашей компании. За время существования нам удалось разработать универсальную схему покупки и доставки реализуемой продукции: покупатель оставляет заявку на приобретение сырья, наши специалисты обрабатывают заказ в тот же день
                                </div>
                            </div>
                            <div className="line"/>
                        </div>
                    </div>
                    <div className="slider">
                        <div className="container">
                            <div className="content">
                                <div className="btns">
                                    <div className={page === 1 ? "btn active" : "btn"} onClick={()=>{setPage(1)}}>Гравийный щебень</div>
                                    <div className={page === 2 ? "btn active" : "btn"} onClick={()=>{setPage(2)}}>ПГС - Песчано-гравийная смесь</div>
                                </div>
                                <div className="pages">
                                    {
                                        page === 1 ? (
                                            <>
                                                <span className="title">Гравийный щебень</span>
                                                <p>Гравийный щебень — многофункциональный строительный материал, который получают путём дробления скальной породы (валунов). Он представляет собой камни, имеющие округлую форму и по происхождению может быть овражным, речным, морским</p>
                                                <span className="title">Применение гравийного щебня</span>
                                                <p>Часто гравийный щебень используют как заполнитель для бетонной смеси. Заполнители в бетоне составляют около 80% общей массы и выбор гравийного щебня способен уменьшить стоимость производства бетона, при этом сохранив все его высокие характеристики и показатели. Кроме этого, гравийный щебень очень востребован при строительстве дорог, а также используется как балластный слой железнодорожных путей.</p>
                                                <p>Гравийный щебень морского и речного происхождения более чистый, чем овражный, но из-за гладкой поверхности он не способен обеспечить хорошее сцепление с вяжущим материалом. Гравийный щебень также широко применяется в ландшафтом проектировании для устройства покрытий, дорожек для пешеходов и площадок. Декоративным гравийным щебнем посыпают клумбы и искусственные водоёмы.</p>
                                                <p>Гравийный щебень фракции 3-10мм используется при обустройстве площадок перед водоёмами и небольших пляжей частных домов, поэтому по нему ходят без обуви. Так, можно сказать, что гравийный щебень востребован и при обустройстве территорий загородной недвижимости.</p>
                                                <p>Благодаря гравийному щебню, можно создать уникальные пешеходные и проезжие дорожки, которые не требуют специального ухода. Для того, чтобы их обустроить, нужно подготовить основание и слой гравийного щебня (около 12см), при этом должны предусматриваться бордюры, т.к. гравийный щебень — это сыпучий строительный материал.</p>
                                                <span className="title">Какие основные характеристики  гравийного щебня?</span>
                                                <div className="numberText">
                                                    <span>• Марка прочности — M-800-M-1200;</span>
                                                    <span>• морозостойкость — F200-F350;</span>
                                                    <span>• лещадность — 2,3 группа.</span>
                                                </div>
                                                <span className="title">Фракции гравийного щебня</span>
                                                <div className="numberText">
                                                    <span>• равийный щебень 3-10мм;</span>
                                                    <span>• гравийный щебень 5-20мм;</span>
                                                    <span>• гравийный щебень 20-40мм;</span>
                                                    <span>• гравийный щебень 40-70мм.</span>
                                                </div>
                                                <span className="title">Преимущества гравийного щебня?</span>
                                                <div className="numberText">
                                                    <span>• невысокая стоимость;</span>
                                                    <span>• низкий уровень радиоактивности;</span>
                                                    <span>• высокий показатель морозостойкости;</span>
                                                    <span>• устойчивость к разным погодным условиям;</span>
                                                    <span>• низкая лещадность.</span>
                                                </div>
                                            </>
                                        ): page === 2 ? (
                                            <>
                                                <span className="title">Песчано-гравийная смесь</span>
                                                <p>Песчано-гравийная смесь -это строительный материал, который представляет собой смесь из гравия и песка. Важнейшая характеристика ПГС это её зерновой состав. Различают 2 вида ПГС природную и обогащенную (ОПГС ). Между собой эти 2 вида выделяются составной структурой.</p>
                                                <span className="title">Природная ПГС бывают:</span>
                                                <div className="numberText">
                                                    <span>• горной это горные полезные ископаемые;</span>
                                                    <span>• озерной, со дна озер;</span>
                                                    <span>• морской, наиболее уникальный состав, за счёт смешения частиц.</span>
                                                </div>
                                                <p>Добыча осуществляется на берегах и дне водных источниках, при помощи специального оборудования.</p>
                                                <span className="title">Песчано-гравийная смесь ГОСТ.</span>
                                                <p>В соответствии с ГОСТ 23735-2014 в природной песчано-гравийной смеси содержание зерен гравия размером более 5 мм должно быть не менее 10% и не более 90% по массе. По объединенному ГОСТУ 23735-79 количество гравия не должно превышать 5%,оставшаяся часть это пески от мелких до крупных.</p>
                                                <span className="title">Песчано-гравийная смесь характеристики</span>
                                                <p>Одной из самых главных характеристик ПГС это её плотность. Усреднённое значение равняется коэффициенту 1.65. Это важно, потому что благодаря нему, можно понять какой элемент преобладающий в структуре.</p>
                                                <span className="title">Итак основные технические параметры ПГС</span>
                                                <div className="numberText">
                                                    <span>• Размер гравийного зерна (гравий должен иметь фракцию от 10 до 70 мм );</span>
                                                    <span>• Особенности песка (минеральный состав, форма и размер песчаного зерна);</span>
                                                    <span>• Устойчивость к морозам и низким температурам (немаловажно для регионов с холодным климатом);</span>
                                                    <span>• Число входящих компонентов ( влияет на прочность возводимых конструкций).</span>
                                                </div>
                                                <p>Требования к ОПГС более строгие, например зерна гравия должны быть от 20 до 40 мм, максимум 70 мм. Стоит отметить, что технические характеристики смесей вне зависимости от классификации зависят от места добычи. Яркая тому иллюстрация, это материал добытый со дна озер, так как он отлично подойдёт для изготовления бетона.</p>
                                                <span className="title">Песчано-гравийная смесь применение</span>
                                                <p>Одним из многих направлений использования в качестве нерудного рыбного строительной отрасли материала. Из неё делают насыпи различных назначений. Роль её заключается также чтобы заполнить пустоты в грунте, и также создать подложки для дорог. В ландшафтном дизайне она популярна как материал из которого создаются клумбы и дорожки.</p>
                                                <p>В соединении с водой и цементом образует цементный раствор, который используют для изготовления бетона.</p>
                                                <p><b>Также она имеет значение для:</b></p>
                                                <div className="numberText">
                                                    <span>• обратной засыпки котлованов, траншей;</span>
                                                    <span>• улучшение окружающей территории;</span>
                                                    <span>• автодорог на определённое время;</span>
                                                    <span>• монтаж детских площадок и дорожек;</span>
                                                    <span>• для рекультивации и в других областях строительства.</span>
                                                </div>
                                            </>
                                        ): setPage(1)
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </React.Fragment>
    );
}

export default Us;